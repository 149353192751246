import React, { useEffect, useState } from "react";
import axios from "axios";
import "./App.css";
import backgroundImage from "./image/backgroudImage5.jpg";

function App() {
  const [comments, setComments] = useState([]);
  const [name, setName] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    const fetchComments = async () => {
      ///////////////////////////////////////////////////////////////////
      // const dummyComments = [
      //   { name: "소연", content: "너무 슬퍼요 ㅠㅠㅠ", createDate: new Date().toISOString() },
      //   { name: "사용자2", content: "두 번째 댓글", createDate: new Date().toISOString() },
      //   { name: "사용자3", content: "세 번째 댓글", createDate: new Date().toISOString() }
      // ];

      // const commentsWithTime = dummyComments.map(comment => ({
      //   ...comment,
      //   time: new Date(comment.createDate).toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })
      // }));
      // setComments(commentsWithTime);
      //////////////////////////////////////////////////////////////////
      try {
        const response = await axios.get(
          "https://t1107.p.ssafy.io/api/comment"
        );
        const commentsWithTime = response.data.map((comment) => ({
          ...comment,
          time: new Date(comment.createDate).toLocaleString([], {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }),
        }));

        commentsWithTime.sort(
          (a, b) => new Date(b.createDate) - new Date(a.createDate)
        );

        setComments(commentsWithTime);
      } catch (error) {
        console.error("댓글 리스트 불러오기 중 오류 발생:", error);
      }
    };

    fetchComments();
  }, []);

  const handleAddComment = async () => {
    if (name && content) {
      const currentDate = new Date();
      const createDate = toLocalISOString(currentDate);
      // console.log(createDate)
      const newComment = {
        name,
        content,
        time: currentDate.toLocaleString([], {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        }),
      };

      try {
        const response = await axios.post(
          "https://t1107.p.ssafy.io/api/comment",
          {
            name: name,
            content: content,
            createDate: createDate,
          }
        );

        if (response.status === 201) {
          setComments([newComment, ...comments]);
          setName("");
          setContent("");
        }
      } catch (error) {
        console.error("댓글 저장 중 오류 발생:", error);
      }
    }
  };

  function toLocalISOString(date) {
    const offsetMs = date.getTimezoneOffset() * 60 * 1000;
    const localTimeMs = date.getTime() - offsetMs;
    const localDate = new Date(localTimeMs);
    return localDate.toISOString().slice(0, 19); // 'YYYY-MM-DDTHH:MM:SS' 형식만 반환
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAddComment();
    }
  };

  return (
    <div className="app">
      <img
        src={backgroundImage}
        alt="background"
        className="background-image"
      />
      <div className="container">
        <div className="content">
          <h1 className="title">스며드는 것</h1>
          <div className="author">안도현</div>
          <div className="poem">
            <p>
              꽃게가 간장 속에
              <br />
              반쯤 몸을 담그고 엎드려 있다
              <br />
              등판에 간장이 울컥울컥
              <br />
              쏟아질 때 꽃게는 뱃속의 알을 껴안으려고
              <br />
              꿈틀거리다가 더 낮게
              <br />
              더 바닥쪽으로 웅크렸으리라
              <br />
              버둥거렸으리라 버둥거리다가
              <br />
              어찌할 수 없어서
              <br />
              살 속으로 스며드는 것을
              <br />
              한 때의 어스름을 꽃게는 천천히 받아들였으리라
              <br />
              껍질이 먹먹해지기 전에 가만히 알들에게 말했으리라
              <br />
              <br />
              저녁이야 <br />불 끄고 잘 시간이야
            </p>
          </div>
        </div>
        <div className="comment-container">
          <div className="comment-input-container">
            <input
              type="text"
              placeholder="이름"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="comment-input-name"
            />
            <input
              type="text"
              placeholder="내용"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              onKeyPress={handleKeyPress}
              className="comment-input-content"
            />
            <button onClick={handleAddComment} className="comment-button">
              입력
            </button>
          </div>
          <div className="comments">
            {comments.map((comment, index) => (
              <div key={index} className="comment">
                <div className="comment-header">
                  <strong>{comment.name}</strong>
                  <span className="comment-time">{comment.time}</span>
                </div>
                <p className="comment-content">{comment.content}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
